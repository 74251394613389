import $ from 'jquery';

export default class Tabs {
    constructor()
    {


		// TABS GROUPS
		$(document).on("click", ".tabs__header .button-outline", function () {

			// PARENT
			var button = $(this);
			var buttons = $('.tabs__header .button-outline--active').not(button);
			var selector = $(this).attr("aria-controls");
			var siblings = $('.tabs__group--active');
			var region = $('#' + selector );

			if(siblings){
				siblings.removeClass('tabs__group--active')
			}
			region.toggleClass('tabs__group--active');

			// REMOVE OLD ACTIVE
			if(buttons){
				buttons.not(button).removeClass('button-outline--active')
			}
			// IF NOT ACTIVE, ACTIVATE 
			if (! button.hasClass('button-outline--active')) {
				button.addClass('button-outline--active');
			}
		
		});

		
    }
}