import $ from 'jquery';

export default class Accordion {
    constructor()
    {

        // ACCORDION
		$(document).on("click", ".accordion__tab", function () {

			// PARENT
			var button = $(this);
			var selector = $(this).attr("aria-controls");
			var region = $('#' + selector );
			var parent = $(this).parents(".accordion");

			if (region.attr("aria-expanded") === "true") {
				region.attr("aria-expanded", "false");
				parent[0].classList.remove('accordion--open');
			} else {
				region.attr("aria-expanded", "true");
				parent[0].classList.add('accordion--open');
			}
		
			// CHANGE ATTR
			if (button.attr("aria-expanded") === "true") {
				button.attr("aria-expanded", "false");
			} else {
				button.attr("aria-expanded", "true");
			}
		
		});

		// ACCORDION GROUPS
		$(document).on("click", ".accordions__filter .button-outline", function () {

			// PARENT
			var button = $(this);
			var buttons = $('.accordions__filter .button-outline--active').not(button);
			var selector = $(this).attr("aria-controls");
			// var parent = $(this).parents('.accordions');
			var siblings = $('.accordions__group--active');
			var region = $('#' + selector );

			// CHECK THE SELECTOR IS NOT FOR ALL
			if(selector == 'all'){
				var region = $('.accordions__group');
			}

			if(siblings){
				siblings.removeClass('accordions__group--active')
			}
			region.toggleClass('accordions__group--active');

			// REMOVE OLD ACTIVE
			if(buttons){
				buttons.not(button).removeClass('button-outline--active')
			}
			// IF NOT ACTIVE, ACTIVATE 
			if (! button.hasClass('button-outline--active')) {
				button.addClass('button-outline--active');
			}
		
		});

		// ACCORDION STYLE 2
		$(document).on("click", ".concertina__tab", function () {

			// PARENT
			var selector = $(this).attr("aria-controls");
			var parent = $(this).parents(".concertina");
			var currentregions = $(parent).children('[aria-expanded="false"]');
			
			// GALLERY
			var images = $('.spotlight__image');
			var image = $(`.spotlight__image[aria-labelledby="${selector}"]`);

			// REMOVE ALL ACTIVE
			var allconcertinas = $('.concertina--open').not(parent);
			if(allconcertinas){
				allconcertinas.each( function() {
					var regions = $(this).children('[aria-expanded="true"]');
					regions.attr("aria-expanded", "false");
					$(this).removeClass('concertina--open');
				});
			}

			if(currentregions){
				currentregions.each( function() {
					$(this).attr("aria-expanded", "true");
				});
			}

			// TOGGLE PARENT CLASS
			parent.addClass('concertina--open');

			// SORT IMAGES
			if(images){
				images.attr("aria-expanded", "false");
			}
			if(image){
				image.attr("aria-expanded", "true");
			}
			
		
		});
    }
}