import $ from 'jquery';

import { lazyBackground, lazyLoadImage } from "../utils/utils";

export default class Observer {
  constructor() {
    this.config = {
      rootMargin: "100px 0px 50px 0px",
      threshold: 0,
    };

    this.observer = new IntersectionObserver((entries, self) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target.tagName === "IMG") {
            lazyLoadImage(entry.target);
            self.unobserve(entry.target);
          } else {
            if (entry.target.hasAttribute("data-background")) {
              lazyBackground(entry.target);
              self.unobserve(entry.target);

              return;
            }

            entry.target.classList.add("loaded");
            entry.target.classList.add("visible");
          }
        }
      });
    }, this.config);

    this.images = document.querySelectorAll(
      '[data-src], [data-background], [loading="lazy"]'
    );

    this.animateElements = document.querySelectorAll('[data-watch="true"]');

    this.images.forEach((image) => {
      this.observer.observe(image);
    });

    this.animateElements.forEach((section) => {
      this.observer.observe(section);
    });
  }
}
