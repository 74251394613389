import $ from 'jquery';

export default class Nav {
	constructor() {
		this.icon = document.querySelector(".nav__icon");

		this.icon &&
		this.icon.addEventListener("click", (event) => {
			event.preventDefault();
			document.querySelector("body").classList.toggle("nav--open");
		});

    /**
     * uncomment this line to add 'sticky nav', make sure to check first line for 'nav height' is   correct
     */
    // document.addEventListener('scroll', this.sticky.bind(this));

	}
	sticky()
	{

		let navHeight = document.querySelector('.nav__content').offsetHeight;
		let triggerHeight = navHeight;
		let currentScroll = $(window).scrollTop();
		let body = $('body');

		let topMargin = window.innerHeight;

		if (currentScroll > triggerHeight)
		{
			if (currentScroll <= this.scrollPos && currentScroll >= topMargin)
			{
				body.addClass("nav--show");
				body.removeClass("nav--hide");
			} else
			{
				body.removeClass("nav--show");
				body.addClass("nav--hide");
			}
		} else
		{
			body.removeClass("nav--show");
			body.removeClass("nav--hide");
		}

		this.scrollPos = $(window).scrollTop();
	}
}
