import $ from 'jquery';

export default class Video {
    constructor(element)
    {

        this.isPlaying = false;
        this.element = element;

        this.video = this.element.querySelector('video');
        this.play = this.element.querySelector('.video__play');

        this.play.addEventListener('click', () =>
        {
            if (this.isPlaying)
            {
                this.video.pause();
                this.element.classList.remove('video--playing');
            } else
            {
                this.video.play();
                this.element.classList.add('video--playing');
            }
        })

    }
}