import $ from 'jquery';


// import "../stylus/style.styl";
import App from "./app/App.js";
import { onLoad } from "./loading/onLoad";

//=======================
// SITE LOADING
//=======================

onLoad();

$(document).ready(function () {
  const mainApplication = new App();
}); // document ready
