/**
 * Shared utilities.
 */

export const lazyLoadImage = (img) => {
  const src = img.getAttribute("data-src");
  const type = img.getAttribute("loading");
  if (type == "lazy") {
    img.classList.add("lazy--loaded");
  }
  if (!src) {
    return;
  }
  var image = new Image();
  image.src = src;
  image.onload = function () {
    img.src = image.src;
    img.classList.add("lazy--loaded");
  };
};

export const lazyBackground = (img) => {
  const src = img.getAttribute("data-background");
  if (!src) {
    return;
  }
  var image = new Image();
  image.src = src;
  image.onload = function () {
    img.style.backgroundImage = 'url("' + image.src + '")';
    img.classList.add("lazy--loaded");
  };
};

// Linear interpolation
export const lerp = (a, b, n) => (1 - n) * a + n * b;

// Gets the mouse position, attach to an event listener...
export const getMousePos = (e) => {
  let posx = 0;
  let posy = 0;
  if (!e) e = window.event;
  if (e.clientX || e.clientY) {
    posx = e.clientX;
    posy = e.clientY;
  }
  return { x: posx, y: posy };
};

// Get sibilings
export const getSiblings = (e) => {
  // for collecting siblings
  let siblings = [];
  // if no parent, return no sibling
  if (!e.parentNode) {
    return siblings;
  }
  // first child of the parent node
  let sibling = e.parentNode.firstChild;
  // collecting siblings
  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== e) {
      siblings.push(sibling);
    }
    sibling = sibling.nextSibling;
  }
  return siblings;
};

export const getDimensions = (el) => {
  return (
    el.classList[0] +
    " dimensions are " +
    "width - " +
    el.offsetWidth +
    " and height - " +
    el.offsetHeight
  );
};

export const convertToSlug = (text) => {
  return text
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

export const convertRange = (value, r1, r2) => {
  return ((value - r1[0]) * (r2[1] - r2[0])) / (r1[1] - r1[0]) + r2[0];
};

export const isOverflown = ({
  clientWidth,
  clientHeight,
  scrollWidth,
  scrollHeight,
}) => {
  return scrollHeight > clientHeight || scrollWidth > clientWidth;
};

export const getRandomFloat = (min, max, decimals) => {
  const str = (Math.random() * (max - min) + min).toFixed(decimals);
  return parseFloat(str);
};

export const degreesToRadians = (degrees) => {
  let pi = Math.PI;
  return degrees * (pi / 180);
};

export const getZIndex = (e) => {
  let z = window.getComputedStyle(e).getPropertyValue("z-index");
  if (isNaN(z)) return window.getZIndex(e.parentNode);
  return parseInt(z);
};

export function SlideNumber( Splide, Components ) {
  const { track } = Components.Elements;

  let elm;

  function mount() {
    elm = document.createElement( 'div' );
    elm.classList.add('splide__numbers');
    elm.style.textAlign = 'center';
    elm.style.marginTop = '0.5em';

    track.parentElement.insertBefore( elm, track.nextSibling );

    update();
    Splide.on( 'move', update );
  }

  function pad(num, size) {
    num = num.toString();
    // console.log(num);
    if (num.length < size){
      num = "0" + num;
    }
    return num;
  }

  function update() {
    
    // console.log(Splide.index);
    // console.log(Splide.length);

    let slideIndex = pad(Splide.index + 1, '10');
    let slideNum = pad(Splide.length, '10');

    elm.innerHTML = `<span>${ slideIndex }</span><span>${ slideNum }</span>`;
  }

  return {
    mount,
  };
}