import $ from 'jquery';

import { lerp, getMousePos, getSiblings } from "../utils/utils";

/**
 * Documentation
 *

To add interaction to an item, set the class of 'cursor-item'.

data-attributes can then be given to control the settings.

data-cursor can be either 'view', 'play' or 'drag'

data-color can be either 'pink', 'green' or 'blue'


/**
 * 
 */

let mouse = { x: 0, y: 0 };

window.addEventListener("mousemove", (ev) => {
  
  mouse = getMousePos(ev);

});

export default class Cursor {
  constructor() {


    window.onmousemove = function(e) {
    
      if (e.target != undefined) {

        if (e.target.classList.contains('map-pin__button')) {
          //	Content of dot which is hovered
          var $target = e.target.childNodes[0].nextSibling;
          
          //	Only work with hovered target
          //	Add visible class hovered elem to distinguish
          if ($target) {
              //	Get viewport offset of tooltip element
              var offset = $target.parentElement.getBoundingClientRect();
              //	Tooltip distance from mouse(px)
              var tipDist = 15;
              //	Calc and set new tooltip location
              $target.style.top = (e.clientY - offset.top + tipDist) + 'px';
              $target.style.left = (e.clientX - offset.left + tipDist) + 'px';
          }
        }
      }
    };

  }

}
