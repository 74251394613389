import $ from 'jquery';

export default function SplideySplide() {

	var elms = document.getElementsByClassName( 'splide--fade' );
	Splide.defaults = {
		perPage: 4,
		type: 'fade',
		rewind : true,
		perPage: 1,
		drag : 'true',
		focus: 'center',
		gap: 0,
		padding: 0,
		pagination: false,
		arrowPath: 'M27.8743 21.5452L22.3591 27.0604L23.3341 28.0354L30.5137 20.8558L23.3341 13.6762L22.3591 14.6511L27.8743 20.1664L12.3038 20.1664L12.3038 21.5452L27.8743 21.5452Z'
	}
	for ( var i = 0; i < elms.length; i++ ) {
		new Splide( elms[ i ] ).mount();
	}

	var elms = document.getElementsByClassName( 'splide--cards' );
	Splide.defaults = {
		type: 'slide',
		rewind: false,
		perPage: 3,
		omitEnd: true,
		perMove: 3,
		autoWidth: true,
		focus: 'left',
		gap: 24,
		padding: 0,
		pagination: false,
		arrowPath: 'M27.8743 21.5452L22.3591 27.0604L23.3341 28.0354L30.5137 20.8558L23.3341 13.6762L22.3591 14.6511L27.8743 20.1664L12.3038 20.1664L12.3038 21.5452L27.8743 21.5452Z',
		breakpoints: {
			768: {
				perPage: 1,
				perMove: 1,
			},
			1024: {
				gap: 24,
				perPage: 2,
				perMove: 2,
			}
		}
	}
	for ( var i = 0; i < elms.length; i++ ) {
		new Splide( elms[ i ] ).mount();
	}

}
