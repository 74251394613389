export const onLoad = () => {
	document.onreadystatechange = function () {

		if (document.readyState === "interactive") {
			document.body.classList.remove("loading");
		}
		if (document.readyState === "complete") {
			document.body.classList.add("loaded");
		}

		let links = document.querySelectorAll('a');
		links.forEach((link) => {

			let target = link.target;
			if( target == '_blank' ){} else {

				link.addEventListener("click", (event) => {

					document.body.classList.add("loading");
					event.preventDefault();

					setTimeout(function(){
						window.location.href = link.href;
					}, 800);

				});

			}

		});

	};
};