import $ from 'jquery';

let instance = null;
import Accordion from "../components/Accordion";
import Tabs from "../components/Tabs";
import Nav from "../components/Nav";
import Observer from "../components/Observer";
import Video from "../components/Video";
import Cursor from "../components/Cursor";
import SplideySplide from "../components/Splide";

export default class App {
	constructor() {
    /**
     * Setup Commands
     */

    // make singleton
		if (instance) {
			return instance;
		}
		instance = this;

    // global access

		window.app = this;

    // get context

		this.getContext();

		window.addEventListener("resize", () => {
			this.getContext();
		});

    /**
     * Components
     */

		this.cursor = new Cursor();
		this.observer = new Observer();
		this.nav = new Nav();
		this.accordion = new Accordion();
    	// this.slideshow = new Slideshow();
		this.tabs = new Tabs();

    // VIDEOS
		this.htmlVideos = document.querySelectorAll('.video');
		this.htmlVideos && this.htmlVideos.forEach((video)=>
		{
			new Video(video);
		})

		SplideySplide();

	}
	getContext() {
    	// Tells us if App is Mobile, and height of Viewport.

		if (window.matchMedia("(max-width: 1024px)").matches) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}

		this.vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty("--vh", `${this.vh}px`);
	}
}
